import styled from 'styled-components';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { BREAKPOINTS } from '@app/styles/themes/constants';

export const SectionWrapper = styled.div`
  .slick-slide > div {
    display: flex;
  }

  .slick-list {
    padding-top: 40px !important;
    padding-bottom: 40px !important;
    margin-top: -40px;
    margin-bottom: -40px;
  }

  @media only screen and (max-width: ${BREAKPOINTS.md - 0.02}px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.25rem;
    margin-bottom: 1.5rem;
  }
`;

export const ViewAllWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const CardWrapper = styled.div`
  margin: 0 0.625rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem; // Adjust gap as needed
  margin-top: 1rem; // Add margin if needed
`;

export const Button = styled(BaseButton)`
  color: var(--text-nft-light-color);
  background-color: transparent; // Adjust background color if needed
  border: 1px solid var(--text-nft-light-color); // Add border if needed
  padding: 0.5rem 1rem; // Adjust padding as needed
  cursor: pointer;
  
  &:hover {
    background-color: var(--hover-background-color); // Define hover color if needed
    color: var(--hover-text-color); // Define hover text color if needed
  }
`;
