import styled from 'styled-components';
import { BaseButton } from '@app/components/common/BaseButton/BaseButton';
import { NFTCard } from '@app/components/nft-dashboard/common/NFTCard/NFTCard';
import { FONT_SIZE, FONT_WEIGHT, FONT_FAMILY, media, BORDER_RADIUS } from '@app/styles/themes/constants';
import { BaseTypography } from '@app/components/common/BaseTypography/BaseTypography';

interface CardInternalProps {
  $img: string;
}

export const BidButton = styled(BaseButton)`
  transition: all 0.5s ease;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 150px;
  padding: 10px 14px;
  color: var(--text-secondary-color);
  border-color: var(--text-secondary-color);
  font-size: ${FONT_SIZE.md};
  z-index: 1; /* Ensure the button is above other content */

  &:hover {
    /* Add hover styles if needed */
  }
`;

export const BackButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  color: white;
  font-size: 16px;
  display: flex;
  align-items: center;
`;

export const CollectionImage = styled.img`
  animation: imgOut 0.5s;
  width: 100%;
  height: 126px;
  object-fit: cover;
  border-top-left-radius: ${BORDER_RADIUS};
  border-top-right-radius: ${BORDER_RADIUS};
`;

export const NftCollectionInfo = styled.div`
  position: relative;
  padding: 2rem 1.25rem 1.5rem;
`;

export const InfoRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-of-type) {
    margin-bottom: 0.1rem;
  }
`;

export const Title = styled(BaseTypography.Title)`
  transition: all 0.5s ease;

  &.ant-typography {
    margin-bottom: 0;
    font-size: ${FONT_SIZE.md};
  }
`;

export const Text = styled(BaseTypography.Text)`
  transition: all 0.5s ease;
  font-size: ${FONT_SIZE.xs};
  font-weight: ${FONT_WEIGHT.semibold};
`;

export const OwnerText = styled(Text)`
  letter-spacing: 0.02em;
  font-size: ${FONT_SIZE.xxs};
  font-weight: ${FONT_WEIGHT.regular};
  font-family: ${FONT_FAMILY.secondary};
  color: var(--text-nft-light-color);
  @media only screen and ${media.xl} {
    font-size: ${FONT_SIZE.xs};
  }
`;

export const USDText = styled(BaseTypography.Text)`
  transition: all 0.5s ease;
  font-weight: ${FONT_WEIGHT.semibold};
  font-size: ${FONT_SIZE.xs};
`;

export const AuthorAvatarWrapper = styled.div`
  transition: all 0.5s ease;
  position: absolute;
  top: -45px;
  border-radius: 50%;
  border: 2px solid var(--text-secondary-color);
`;

export const Card = styled(NFTCard)<CardInternalProps>`
  overflow: hidden;
  border: none; /* Ensure no default border */

  &:hover {
    background: ${(props) => `url(${props.$img})`};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;

    ${CollectionImage} {
      animation: imgIn 0.5s;
      animation-fill-mode: forwards;
    }

    ${Title}, ${Text}, ${USDText} {
      color: var(--text-secondary-color);
    }

    ${AuthorAvatarWrapper} {
      transform: translateY(-70px) scale(1.1);
    }

    ${BidButton} {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1.1);
      position: absolute;
    }

    border: none !important; /* Ensure no border on hover */
  }

  @keyframes imgIn {
    99% {
      transform: scale(2);
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes imgOut {
    0% {
      transform: scale(2);
    }

    100% {
      transform: scale(1);
    }
  }
`;

export const ActivityCard = styled(NFTCard)`
  box-shadow: var(--box-shadow-nft-secondary-color);
`;

export const Wrapper = styled.div`
  display: flex;
  gap: 1.625rem;
`;

export const ImgWrapper = styled.div`
  position: relative;
`;

export const IconWrapper = styled.div`
  width: 1.4375rem;
  height: 1.4375rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--text-secondary-color);

  background-color: var(--primary-color);

  font-size: ${FONT_SIZE.xs};
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  flex: 1;
`;

export const InfoHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
`;

export const InfoBottomWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;


export const DateText = styled(Text)`
  font-style: italic;

  font-family: ${FONT_FAMILY.main};
`;
