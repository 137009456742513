import React from 'react';
import {
  HomeFilled,
  UserOutlined,
} from '@ant-design/icons';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const sidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'Home',
    key: 'nft-dashboard',
    url: '/',
    icon: <HomeFilled />,
  },
];