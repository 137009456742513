import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TrendingActivity } from '@app/api/activity.api';
import * as S from './TrendingCollection.styles';
import { Button } from 'antd';

export const TrendingCollection: React.FC<TrendingActivity> = ({ title }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    if (title === 'Community Case Management') {
      navigate('/community-case-management'); // Navigate to Community Case Management
    } else {
      navigate('/supervisory-modules-dashboard'); // Navigate to Supervisory Dashboard
    }
  };

  const openLabel = t('View Module');

  return (
    <>
      <S.ActivityCard>
        <S.Wrapper>
          <S.InfoWrapper>
            <S.InfoHeaderWrapper>
              <S.TitleWrapper>
                <S.Title level={1}>{title}</S.Title>
                <Button type='primary' onClick={handleClick}>{openLabel}</Button>
              </S.TitleWrapper>
            </S.InfoHeaderWrapper>
          </S.InfoWrapper>
        </S.Wrapper>
      </S.ActivityCard>
    </>
  );
};
