import { useState, useEffect } from 'react';
import { PageTitle } from '@app/components/common/PageTitle/PageTitle';
import { ScreeningsCard } from '@app/components/medical-dashboard/screeningsCard/ScreeningsCard/ScreeningsCard';
import { References } from '@app/components/common/References/References';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './DashboardPage.styles';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import axios from 'axios';
import { ScatterChart } from '@app/components/charts/ScatterChart/ScatterChart';
import { SupervisoryModulesPieChart } from '@app/components/charts/SupervisoryModulesPieChart';
import { BaseChart } from '@app/components/common/charts/BaseChart';
import { ActivityChart } from '@app/components/medical-dashboard/activityCard/ActivityChart';
import { ActivityCard } from '@app/components/nft-dashboard/recentActivity/recentActivityFeed/RecentActivityItem/RecentActivityItem.styles';
import { BaseCard } from '@app/components/common/BaseCard/BaseCard';
import { useTranslation } from 'react-i18next';
import { Typography, Spin, Progress } from 'antd';

interface User {
  id: string;
  first_name: string;
  last_name: string;
  avatar: string;
  location: string;
}

const MedicalDashboardPage: React.FC = () => {

  const { isTablet, isDesktop } = useResponsive();

  const { t } = useTranslation();

  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/me`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
          },
        });
        setUser(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const desktopLayout = (
    <BaseRow style={{ paddingTop: '20px', paddingLeft: '20px', paddingRight: '20px' }}>

      <BaseCol id="activity" span={24} style={{ marginTop: '20px' }}>
        <BaseRow gutter={[30, 30]}>
          <BaseCol id="pie" xs={24} lg={24}>
            {/* <GradientStackedAreaChart /> */}
          </BaseCol>
        </BaseRow>
      </BaseCol>

      <References />
    </BaseRow>
  );

  const mobileAndTabletLayout = (
    <BaseRow style={{ paddingTop: '20px', paddingLeft: '20px', paddingRight: '20px' }}>
      <BaseRow gutter={[30, 30]}>
      
        <BaseCol id="activity" span={24} style={{ marginTop: '20px' }}>
          <BaseRow gutter={[30, 30]}>
            <BaseCol id="latest-screenings" span={24}>
           
            </BaseCol>

          </BaseRow>
        </BaseCol>
      </BaseRow>
    </BaseRow>
  );

  return (
    <>
      <PageTitle>{t('common.medical-dashboard')}</PageTitle>
      {isDesktop ? desktopLayout : mobileAndTabletLayout}
    </>
  );
};

export default MedicalDashboardPage;