import React, { useEffect, useState } from 'react';
import { ProfileOverlay } from '../ProfileOverlay/ProfileOverlay';
import { useResponsive } from '@app/hooks/useResponsive';
import * as S from './ProfileDropdown.styles';
import { BasePopover } from '@app/components/common/BasePopover/BasePopover';
import { BaseCol } from '@app/components/common/BaseCol/BaseCol';
import axios from 'axios';
import moment from 'moment';
import { BaseAvatar } from '@app/components/common/BaseAvatar/BaseAvatar';
import { BaseRow } from '@app/components/common/BaseRow/BaseRow';

interface User {
  imgUrl: ReactNode;
  status: string;
  last_access: string;
  id: string;
  first_name: string;
  last_name: string;
  avatar: string;
}

const fetchUserData = async (setUser: React.Dispatch<React.SetStateAction<User | null>>) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/me`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
      },
    });
    setUser(response.data.data);
  } catch (error) {
    console.error('Error fetching user data:', error);
  }
};

export const ProfileDropdown: React.FC = () => {
  const { isTablet } = useResponsive();
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    fetchUserData(setUser);
  }, []);

  return user ? (
    <BasePopover content={<ProfileOverlay />} trigger="click">
      <S.ProfileDropdownHeader as={BaseRow} gutter={[10, 10]} align="middle">
        <BaseCol>
          <BaseAvatar src={user.imgUrl} alt="User" shape="circle" size={40} />
        </BaseCol>
  
          <BaseCol>
            <span>{user.first_name}<br /> {user.last_name}</span>
          </BaseCol>

      </S.ProfileDropdownHeader>
    </BasePopover>
  ) : null;
};
