import React from 'react';
import * as S from './References.styles';

export const References: React.FC = () => {
  return (
    <S.ReferencesWrapper>
      <S.Text>
        Ministry of Community Development and Social Services - Vulnerable Child Adolescent{' '}
        <a href="https://www.mcdss.gov.zm/" rel="noreferrer">
          Program Management Platform{' '}<br />
        </a>
        &copy;.{' '}
        <a href="https://www.mcdss.gov.zm/" rel="noreferrer">
          2024
        </a>
      </S.Text>
    </S.ReferencesWrapper>
  );
};
