import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { TrendingCollection } from '@app/components/nft-dashboard/trending-collections/collection/ParentCollection';
import { useResponsive } from '@app/hooks/useResponsive';
import { getTrendingActivities, TrendingActivity } from '@app/api/activity.api';
import * as S from './TrendingCollections.styles';
import { BaseCarousel } from '@app/components/common/BaseCarousel/Carousel';

export const MainDashboardPage: React.FC = () => {
  const [trending, setTrending] = useState<TrendingActivity[]>([]);

  const { mobileOnly, isTablet: isTabletOrHigher } = useResponsive();

  useEffect(() => {
    getTrendingActivities().then((res) => setTrending(res));
  }, []);

  const { t } = useTranslation();

  const trendingList = useMemo(() => {
    return {
      mobile: trending.map((item, index) => <TrendingCollection key={index} {...item} />).slice(0, 3),
      tablet: trending.map((item, index) => (
        <div key={index}>
          <S.CardWrapper>
            <TrendingCollection {...item} />
          </S.CardWrapper>
        </div>
      )),
    };
  }, [trending]);

  const sliderRef = useRef<Slider>(null);

  return (
    <>
      <S.SectionWrapper>
        {mobileOnly && trendingList.mobile}
        {isTabletOrHigher && trending.length > 0 && (
          <BaseCarousel
            ref={sliderRef}
            slidesToShow={2}
            responsive={[
              {
                breakpoint: 1900,
                settings: {
                  slidesToShow: 2,
                },
              },
            ]}
          >
            {trendingList.tablet}
          </BaseCarousel>
        )}
      </S.SectionWrapper>
    </>
  );
};

export default MainDashboardPage;