import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import * as S from './SiderMenu.styles'; // Adjust import based on your project structure
import { sidebarNavigation } from '../ParentSidebarNavigation'; // Adjust based on your project structure
import { PMPsidebarNavigation } from '../PMPsidebarNavigation'; // Adjust based on your project structure
import { SupervisoryToolSsidebarNavigation } from './SupervisoryToolSsidebarNavigation';

interface SiderContentProps {
  setCollapsed: (isCollapsed: boolean) => void;
}

const SiderMenu: React.FC<SiderContentProps> = ({ setCollapsed }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const isCommunityCaseManagementPage = location.pathname === '/community-case-management';

  const isSupervisoryPage = [
    
    '/community-development-self-assessment-dashboard',
    '/social-welfare-district-self-assessment-dashboard',

    '/forms/case-management-district-self-assessment',
    '/forms/stores-district-self-assessment',
    '/forms/procurement-district-self-assessment',
    '/forms/human-resource-district-self-assessment',
    '/forms/finance-district-self-assessment',
    
    '/case-management-feedback-district-dashboard',
    '/finance-feedback-district-dashboard',
    '/stores-feedback-district-dashboard',
    '/procurement-feedback-district-dashboard',
    '/human-resource-feedback-district-dashboard',
    
  ].some(path => location.pathname.includes(path));

  const isPMPPage = [
    '/household-profile/:household_id',
    '/vca-profile/:unique_id',
    '/member-profile/:unique_id',
    '/apps/households-register',
    '/apps/vcas-register',
    '/apps/households-members-register',
  ].some(path => location.pathname.includes(path.split(':')[0]));

  // Filter navigation items based on the current page
  let filteredNavigation = sidebarNavigation.filter(item =>
    isCommunityCaseManagementPage ? item.key === 'medical-dashboard' : item.key === 'nft-dashboard'
  );

  // Optionally include PMP navigation if on community-case-management page or PMP pages
  if (isCommunityCaseManagementPage || isPMPPage) {
    filteredNavigation = [...filteredNavigation, ...PMPsidebarNavigation];
  }

  // Include supervisory tools navigation if on supervisory page
  if (isSupervisoryPage) {
    const modifiedSupervisoryNav = SupervisoryToolSsidebarNavigation.map(item => {
      // Set the District Performance link based on the active route
      if (item.key === 'district-dashboard') {
        return {
          ...item,
          url: location.pathname === '/community-development-self-assessment-dashboard'
            ? '/community-development-self-assessment-dashboard' // Keep the existing URL
            : '/social-welfare-district-self-assessment-dashboard', // Keep the existing URL
        };
      }
      return item;
    });

    filteredNavigation = [...filteredNavigation, ...modifiedSupervisoryNav];
  }

  return (
    <S.Menu
      mode="inline"
      onClick={() => setCollapsed(true)}
      items={filteredNavigation.map(nav => ({
        key: nav.key,
        title: t(nav.title),
        label: <Link to={nav.url || ''}>{t(nav.title)}</Link>,
        icon: nav.icon,
        // No children for the top-level item now
      }))}
    />
  );
};

export default SiderMenu;
