import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getTrendingActivities, TrendingActivity } from '@app/api/activity.api';
import { Button, Skeleton, Typography, Tabs, notification, Modal } from 'antd'; // Import Modal
import axios from 'axios';
import MainDashboardPage from './MainDashboardPage';
import { HintText } from '@app/components/apps/newsFeed/NewsFilter/NewsFilter.styles';
import { useNavigate } from 'react-router-dom';
import { persistUser } from '@app/services/localStorage.service';

const { TabPane } = Tabs;

interface User {
  id: string;
  first_name: string;
  last_name: string;
  avatar: string;
  location: string;
}

export const MedicalDashboardPage: React.FC = () => {
  const [trending, setTrending] = useState<TrendingActivity[]>([]);
  const { t } = useTranslation();
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [notificationShown, setNotificationShown] = useState(false);
  const navigate = useNavigate();
  const notificationRef = useRef<string | null>(null);

  // State for changelog modal
  const [isChangelogModalVisible, setIsChangelogModalVisible] = useState(false);
  const [changelogContent, setChangelogContent] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/users/me`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        });
        const userData = response.data.data;
        setUser(userData);
        persistUser(userData);
      } catch (error) {
        console.error('Error fetching user data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const fetchTrendingActivities = async () => {
      try {
        const res = await getTrendingActivities();
        setTrending(res);
      } catch (error) {
        console.error('Error fetching trending activities:', error);
      }
    };

    fetchTrendingActivities();
  }, []);

  useEffect(() => {
    if (user && !notificationShown) {
      const key = 'user-management-notification';
      notification.open({
        key,
        message: 'Access Granted!',
        description: (
          <>
            You have access to the user management portal.
            <br />
            <Button onClick={() => navigate('/users-management-portal')} type="primary">
              View Users
            </Button>
          </>
        ),
        placement: 'topRight',
        duration: null, // Keep notification visible until manually closed
        style: { width: 300, margin: '16px' },
      });
      notificationRef.current = key;
      setNotificationShown(true);

      // Set a timeout to automatically close the notification after 5 seconds
      setTimeout(() => {
        if (notificationRef.current) {
          notification.close(notificationRef.current);
          notificationRef.current = null;
        }
      }, 5000);
    }
  }, [navigate, notificationShown, user]);

  // Fetch or define changelog content
  useEffect(() => {
    // Simulating fetching changelog data
    const changelogData = `
      ## Latest Updates
      - Added new user management features.
      - Improved dashboard performance.
      - Fixed bugs in the supervisory modules.
    `;
    setChangelogContent(changelogData);
  }, []);

  // Functions to handle changelog modal visibility
  const showChangelogModal = () => {
    setIsChangelogModalVisible(true);
  };

  const handleChangelogModalClose = () => {
    setIsChangelogModalVisible(false);
  };

  return (
    <>
      <div style={{ padding: '25px 50px 25px 50px' }}>
        <Typography>
          Hi there 👋 {user?.first_name}, <br />Welcome back!
        </Typography>
        <Typography.Title level={4}>
          {loading ? <Skeleton active paragraph={{ rows: 1 }} /> : `${user?.location}`} District
        </Typography.Title>
        <HintText>Choose an option to get started</HintText>
        <Tabs defaultActiveKey="1">
          <TabPane tab="User Management Portal" key="1">
            <Typography>You have rights to the user management portal</Typography>
            <Button onClick={() => navigate('/users-management-portal')} type="primary">
              Go to Users
            </Button>
          </TabPane>
          <TabPane tab="HQ / Provincial Supervisory Modules Feedback" key="2">
            <Typography>You have rights to view HQ and Province Supervisory Modules</Typography>
            <Button onClick={() => navigate('/hq-province-supervisory-tools-feedback-dashboard')} type="primary">
              View Dashboards
            </Button>
          </TabPane>
          <TabPane tab="Application Changelog" key="3">
            {/* Trigger to open the changelog modal */}
            <Button type="primary" onClick={showChangelogModal}>
              View New Updates
            </Button>
          </TabPane>
        </Tabs>
      </div>
      <br />
      <Modal
        title="Changelog"
        visible={isChangelogModalVisible}
        onCancel={handleChangelogModalClose}
        footer={[
          <Button key="close" onClick={handleChangelogModalClose}>
            Close
          </Button>,
        ]}
      >
        <Typography.Paragraph>
          {changelogContent}
        </Typography.Paragraph>
      </Modal>
      <MainDashboardPage />
    </>
  );
};

export default MedicalDashboardPage;
