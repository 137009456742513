import React from 'react';
import { AppstoreAddOutlined, DashboardFilled, HomeOutlined, MoneyCollectOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { icon } from 'leaflet';

export interface SidebarNavigationItem {
  title: string;
  key: string;
  url?: string;
  children?: SidebarNavigationItem[];
  icon?: React.ReactNode;
}

export const SupervisoryToolSsidebarNavigation: SidebarNavigationItem[] = [
  {
    title: 'District Dashboard',
    key: 'district-dashboard',
    url: '', // Will be overridden dynamically
    icon: <DashboardFilled />,
  },
  {
    title: 'Case Mgmt Assessment',
    key: 'case-management',
    url: '/forms/case-management-district-self-assessment',
    icon: <UserAddOutlined />
  },
  {
    title: 'Finance Assessment',
    key: 'advanced-forms',
    url: '/forms/finance-district-self-assessment',
    icon: <MoneyCollectOutlined />
  },
  {
    title: 'Human Resource',
    key: 'finance',
    url: '/forms/human-resource-district-self-assessment',
    icon: <UserOutlined />
  },
  {
    title: 'Procurement Assessment',
    key: 'procurement',
    url: '/forms/procurement-district-self-assessment',
    icon: <AppstoreAddOutlined />
  },
  {
    title: 'Stores Assessment',
    key: 'stores',
    url: '/forms/stores-district-self-assessment',
    icon: <HomeOutlined />
  },
];