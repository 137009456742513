import { ActivityStatusType } from '@app/interfaces/interfaces';

export interface Activity {
  title: string;
}

export interface TrendingActivity {
  title: string;
}


export interface UserActivity extends Omit<Activity, 'owner'> {

}

export const getTrendingActivities = (): Promise<TrendingActivity[]> => {
  return new Promise((res) => {
    setTimeout(() => {
      res([
        {
          title: 'Community Case Management',
        },
        {
          title: 'Supervisory Tools',
        },
      ]);
    }, 0);
  });
};

export const getActivities = (): Promise<Activity[]> => {
  return new Promise((res) => {
    setTimeout(() => {
      res([
        {
          title: 'Case Management'
        },
        {
          title: 'Finance'
        },
        {
          title: 'Human Resource'
        },
        {
          title: 'Procurement'
        },
        {
          title: 'Stores'
        },
      ]);
    }, 1000);
  });
};


export const getProvinceActivities = (): Promise<Activity[]> => {
  return new Promise((res) => {
    setTimeout(() => {
      res([
        { title: 'Lusaka' },
        { title: 'Central' },
        { title: 'Copperbelt' },
        { title: 'Eastern' },
        { title: 'Luapula' },
        { title: 'Muchinga' },
        { title: 'Northern' },
        { title: 'North-Western' },
        { title: 'Southern' },
        { title: 'Western' },
      ]);
    }, 1000);
  });
};


export const getUserActivities = (): Promise<UserActivity[]> => {
  return new Promise((res) => {
    setTimeout(() => {
      res([
        {
          title: 'You have no previous activities saved.',
        },
        // {
        //   title: 'Deep Cleaning',
        // },
        // {
        //   title: 'Deep Cleaning',
        // },
        // {
        //   title: 'Deep Cleaning',
        // },
        // {
        //   title: 'Deep Cleaning',
        // },
        // {
        //   title: 'Deep Cleaning',
        // },
        // {
        //   title: 'Deep Cleaning',
        // },
        // {
        //   title: 'Deep Cleaning',
        // },
        // {
        //   title: 'Deep Cleaning',
        // },
        // {
        //   title: 'Deep Cleaning',
        // },
        // {
        //   title: 'Deep Cleaning',
        // },
        // {
        //   title: 'Deep Cleaning',
        // },
        // {
        //   title: 'Deep Cleaning',
        // },
        // {
        //   title: 'Deep Cleaning',
        // },
        // {
        //   title: 'Deep Cleaning',
        // },
        // {
        //   title: 'Deep Cleaning',
        // },
        //  {
        //   title: 'Deep Cleaning',
        // },
      ]);
    }, 0);
  });
};
